<script setup>
import { ref } from 'vue';
import FormStepper from '@/components/FormStepper.vue';
import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
import notify from '@/notify.js';
import { gql } from '@apollo/client/core';
import { t, simpleLocale } from '@/i18n';

const stepper = ref(null);
</script>
<template>
    <h1 class='mt-5 mb-7 header-font'>
        {{ $t('btn-create-new-sync-connection') }}
    </h1>
    <FormStepper
        :steps='[
            {
                key: "step-1",
                title: t("ttl-create-new-connection-step-1"),
            },
            {
                key: "step-2",
                title: t("ttl-create-new-connection-step-2"),
            }
        ]'
        v-slot='{ currentStep, nextStep }'
        ref='stepper'
    >
        <div class='text-center flex flex-col items-center justify-center mb-auto'>
            <template v-if='currentStep === "step-1" && environment.length>1'>
                <h2 class='flex  mb-7 text-4xl mt-5 '>
                    {{ $t('ttl-create-new-connection-step-1') }}
                </h2>
                <div class='mt-4 mb-7  flex justify-end text-start'>
                    {{ $t('txt-multiple-env-explanation-step-1-1') }}
                    <br>
                    <br>
                    {{ $t('txt-multiple-env-explanation-step-1-2') }}
                </div>
                <div class='border border-solid border-grey-200 p-6 inline-block rounded-md' style='width: 70%;'>
                    <!-- Search bar to be used for when we'll allow user to create POD clients based on Clients -->
                    <div v-if='typeClients' class='search-wrapper'>
                        <input type='text' v-model='search' placeholder='Search title..' style='width: 80%;'>
                        <label>Search title:</label>
                        <br>
                        <br>
                    </div>
                    <div class='inline-block text-start' style='width: 80%;'>
                        <label v-for='env in envFilteredList' :key='env.name' class='connection-type'>
                            <input type='radio' :value='env.name' v-model='picked' class='radio-button'>
                            <span>{{ env.name }}</span>
                            <br>
                        </label>
                    </div>
                </div>
                <div class='mt-6  grid grid-cols-1 gap-10' style='position: absolute; bottom: 5%;'>
                    <FriendlyButton
                        label='btn-create-connection-confirm'
                        no-margin
                        fullwidth
                        square
                        extra-small
                        @click='nextStep'
                        :disabled='!picked'
                        red
                    />
                </div>
            </template><template v-if='currentStep === "step-1" && environment.length==1'>
                <h2 class='flex  mb-7 text-4xl mt-5 '>
                    {{ $t('ttl-create-new-connection-one-env-step-1') }}
                </h2>
                <div class='mt-4 mb-7 ml-5'>
                    {{ $t('txt-one-env-explanation-step-1') }} <br>
                    <strong>{{ picked }}.</strong>
                </div>

                <div class='mt-6 grid grid-cols-1 gap-5' style='position: absolute; bottom: 5%;'>
                    <FriendlyButton
                        label='btn-create-connection-confirm'
                        no-margin
                        fullwidth
                        square
                        extra-small
                        @click='nextStep'
                        red
                    />
                </div>
            </template>
            <template v-if='currentStep === "step-2"'>
                <h2 class='flex  mb-4 text-4xl mt-4 '>
                    {{ $t('ttl-create-new-connection-step-2') }}
                </h2>
                <div class='ml-5 text-start'>
                    <p class='mb-3 bg-green-50 border border-green-400 text-green-700 px-4 py-3 rounded'>
                        {{ $t('txt-banner-step-2') }}:  <strong> {{ picked }}</strong>. <br>
                    </p>
                    <!-- --- -->
                    <h3 class='flex mb-2 text-2xl mt-2 '>
                        {{ $t('txt-explanation-step-2-header-1') }}
                    </h3>
                    <p class='mb-3'>
                        {{ $t('txt-explanation-step-2-paragraph-1') }} <br>
                    </p>
                    <div class='mt-3 mb-6 text-center'>
                        <a :href='downloadLink' target='_blank' class='mr-2'>
                            <FriendlyButton
                                square
                                extra-small
                                no-margin
                                label='btn-download'
                                symbol='windows'
                                fullwidth
                                secondary
                            />
                        </a>
                        <a :href='downloadLink' target='_blank' class='ml-2'>
                            <FriendlyButton
                                square
                                extra-small
                                no-margin
                                label='btn-download'
                                fullwidth
                                symbol='apple'
                                secondary
                            />
                        </a>
                    </div>
                    <h3 class='flex mb-2 text-2xl mt-2 '>
                        {{ $t('txt-explanation-step-2-header-2') }}
                    </h3>
                    <p class='mb-3'>
                        {{ $t('txt-explanation-step-2-paragraph-2') }} <br>
                    </p>
                    <p class='mt-3 mb-6 text-center'>
                        <FriendlyButton
                            :action='() => copyToClipboard(token)'
                            symbol='clipboard'
                            extra-small
                            no-margin
                            square
                            secondary
                            fullwidth
                            :disabled='loading'
                        >
                            <span class='font-mono uuid align-middle w-auto'>XXXXX-XXXXX</span>
                            <span v-if='loading' class='font-mono uuid align-middle w-auto'>generating code...</span>
                            <span v-if='!loading' class='font-mono uuid align-middle w-auto'>{{ token }}</span>
                        </FriendlyButton>
                    </p>
                    <p class='mt-3'>
                        {{ $t('txt-explanation-step-2-paragraph-3') }}
                    </p>
                    <p class='mt-3'>
                        {{ $t('txt-explanation-step-2-paragraph-4') }}
                    </p>
                    <a :href='downloadLink' target='_blank'>
                        {{ $t('txt-explanation-step-2-paragraph-4-link') }}
                    </a>
                </div>
                <div class='mt-6 grid grid-cols-1 gap-10' style='position: absolute; bottom: 4%; width: 15%;'>
                    <FriendlyButton
                        no-margin
                        label='btn-close'
                        :action='onDone_close'
                        symbol='times'
                        extra-small
                        square
                    />
                </div>
            </template>
        </div>
    </FormStepper>
</template>

<script>
export default {
    props: {
        onDone: Function,
        onError: Function,
        podUuid: String,
        podName: String,
        environment: Array,
    },
    data () {
        return {
            loading: false,
            token: null,
            picked: this.environment[0].name,
            search: '',
        };
    },
    computed: {
        language () {
            return simpleLocale(this.$i18n.locale);
        },
        downloadLink () {
            return {
                en: 'https://faq.codabox.com/en/support/solutions/articles/75000085068-codabox-synctool-user-manual#download',
                fr: 'https://faq.codabox.com/fr/support/solutions/articles/75000085068-codabox-synctool-user-manual#download',
                nl: 'https://faq.codabox.com/nl/support/solutions/articles/75000085068-codabox-synctool-user-manual#download',
            }[this.language];
        },
        envFilteredList () {
            return this.environment.filter((env) => {
                return env.name.toLowerCase().includes(this.search.toLowerCase());
            });
        },
    },
    created () {
        this.getToken();
    },
    methods: {
        async getToken () {
            this.loading = true;
            this.token = await this.generateToken();
            this.loading = false;
        },
        async generateToken () {
            try {
                const results = await this.$apollo.query({
                    query: gql`query createSyncActivation($podUuid: String) {
                        createSyncActivation(podUuid: $podUuid) {
                            token
                        }
                    }`,
                    variables: {
                        podUuid: this.podUuid,
                    },
                });
                this.token = results.data.createSyncActivation.token;
                return this.token;
            } catch (e) {
                notify.error(this.$t('err-unknown'));
            } finally {
                this.loading = false;
            }
        },
        async copyToClipboard (token) {
            try {
                await navigator.clipboard.writeText(token);
                notify.success(this.$t('p-sync-activation-token-copied-clipboard-success'));
            } catch (err) {
                notify.error(this.$t('p-sync-activation-token-copied-clipboard-error'));
            }
        },
        onDone_close () {
            this.onDone();
        },
    },
};
</script>

<style lang="postcss" scoped>
.info-row {
    @apply mb-2;
}

.info-row:last-child {
    @apply mb-0;
}

.info-row span {
    @apply font-bold mr-2;
}
.uuid {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.header-font {
    font-size: x-large;
}
  .search-wrapper {
    position: relative;
    label {
      position: absolute;
      font-size: 12px;
      color: rgba(0,0,0,.50);
      top: 8px;
      left: 12px;
      z-index: -1;
      transition: .15s all ease-in-out;
    }
    input {
      padding: 4px 12px;
      color: rgba(0,0,0,.70);
      border: 1px solid rgba(0,0,0,.12);
      transition: .15s all ease-in-out;
      background: white;
      &:focus {
        outline: none;
        transform: scale(1.05);
        & + label  {
          font-size: 10px;
          transform: translateY(-24px) translateX(-12px);
        }
      }
      &::-webkit-input-placeholder {
          font-size: 12px;
          color: rgba(0,0,0,.50);
          font-weight: 100;
      }
    }
  }
  .connection-type {
    background-color: rgba(12, 81, 255, 0.05);
    color: #0c52ff;
    border: solid;
    border-width: 1px;
    border-color: #0c52ff;
    border-radius: 8px;
    padding: 0.5em;
    margin: 1em auto;
    cursor: pointer;
    width: 100%;
    display: block;
}
.radio-button{
    vertical-align: middle;
    margin-right: 5px;
}
</style>
